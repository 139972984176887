<template>
  <div >
    <img class="qrcode" v-bind="qrcode"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  created () {
    // this.qrcode = { src: 'https://dss2.baidu.com/6ONYsjip0QIZ8tyhnq/it/u=382657838,3673701766&fm=85&app=79&f=JPG?w=121&h=75&s=17F4E936D4A06903597403EC02007036' }
    this.qrcode = { src: store.getters.userInfo.qrcode }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data () {
    return {
      qrcode: {}
    }
  }
}
</script>
<style lang="less">
 .qrcode {
   width: 100%;
   padding-top: 20px;
   align-content: center
 }
</style>
